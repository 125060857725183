function createObject() {
  let example = {
    id: "LBST_1+1_Fehler_Gruppen_V2",
    showGroupNames: false,
    checkAfterHowManyItems: 3,
    checkAfterExactItemCount: true,
    workOrder: `Die folgenden Kärtchen zeigen Fehler in Rechenaufgaben. Versuchen Sie nachzuvollziehen, wie die Kinder auf ihr Ergebnis gekommen sind. Ziehen Sie ähnliche Fehler jeweils in eine Gruppe.`,
    possibleCategories: [
      {
        id: 1,
        name: "Eins-und-eins-Fehler der Nähe um -1",
      },
      {
        id: 2,
        name: "Verwechseln der Operation",
      },
      {
        id: 3,
        name: "Zwischenergebnis als Lösung",
      },
    ],
    elements: [
      {
        id: 4,
        video: require("@/assets/Test_Video.mp4"),
        category: 2,
        flex: 3,
      },
      {
        id: 1,
        video: require("@/assets/Test_Video.mp4"),
        category: 1,
        flex: 3,
      },
      {
        id: 7,
        video: require("@/assets/Test_Video.mp4"),
        category: 3,
        flex: 3,
      },
      {
        id: 2,
        video: require("@/assets/Test_Video.mp4"),
        category: 1,
        flex: 3,
      },
      {
        id: 5,
        video: require("@/assets/Test_Video.mp4"),
        category: 2,
        flex: 3,
      },
      {
        id: 8,
        video: require("@/assets/Test_Video.mp4"),
        category: 3,
        flex: 3,
      },
      {
        id: 6,
        video: require("@/assets/Test_Video.mp4"),
        category: 2,
        flex: 3,
      },
      {
        id: 9,
        video: require("@/assets/Test_Video.mp4"),
        category: 3,
        flex: 3,
      },
      {
        id: 3,
        video: require("@/assets/Test_Video.mp4"),
        category: 1,
        flex: 3,
      },
    ],
  };

  const zweiFehler =
    "Zwei der Rechnungen weisen einen Fehler auf, bei dem das Ergebnis um 1 kleiner ist als das richtige Ergebnis, ";

  const zweiVerwechseln =
    "Zwei der Rechnungen weisen einen Fehler auf, bei dem subtrahiert wurde, anstatt zu addieren, ";

  const zweiZwischen =
    "Zwei der Rechnungen weisen einen Fehler auf, bei dem das Ergebnis einer Zwischenrechnung als Lösung genommen wurde, ";

  const einsFehler =
    "eine Rechnung weist jedoch einen Fehler auf, bei dem das Ergebnis um 1 kleiner ist als das richtige Ergebnis. Welches Kärtchen passt nicht? Versuchen Sie es erneut.";

  const einsVerwechseln =
    "eine Rechnung weist jedoch einen Fehler auf, bei dem subtrahiert wurde, anstatt zu addieren. Welches Kärtchen passt nicht? Versuchen Sie es erneut.";

  const einsZwischen =
    "eine Rechnung weist jedoch einen Fehler auf, bei dem das Ergebnis einer Zwischenrechnung als Lösung genommen wurde. Welches Kärtchen passt nicht? Versuchen Sie es erneut.";

  example.responses = [
    {
      id: "030",
      category: example.possibleCategories[1],
      text:
        "Sehr gut. Bei diesen drei Rechnungen, wird immer subtrahiert, anstatt zu addieren. Dieser Fehlertyp wird Verwechseln der Operation genannt und tritt meist nur dann auf, wenn der erste Summand größer als der zweite Summand ist.",
    },
    {
      id: "012",
      text: zweiZwischen + einsVerwechseln,
    },
    {
      id: "021",
      text: zweiVerwechseln + einsZwischen,
    },
    {
      id: "003",
      category: example.possibleCategories[2],
      text:
        "Klasse. Bei diesen drei Rechnungen, wird immer das Ergebnis einer Zwischenrechnung als Lösung genommen. Dieser Typ kann als Zwischenergebnisse als Lösung bezeichnet werden. Bei diesem Fehlertypen wird als Endergebnis das Zwischenergebnis der einfacheren Ableitungsaufgabe genommen.",
    },
    {
      id: "102",
      text: zweiZwischen + einsFehler,
    },
    {
      id: "111",
      text:
        "Upps. Das ist noch sehr durcheinander. Welche Rechnungen zeigen Fehler, bei dem das Ergebnis um 1 kleiner ist als das richtige Ergebnis? Gibt es Rechnungen, bei denen subtrahiert wurde, anstatt zu addieren oder welche bei denen das Ergebnis einer Zwischenrechnung als Lösung genommen wurde? Versuchen Sie es erneut.",
    },
    {
      id: "120",
      text: zweiVerwechseln + einsFehler,
    },
    {
      id: "201",
      text: zweiFehler + einsZwischen,
    },
    {
      id: "210",
      text: zweiFehler + einsVerwechseln,
    },
    {
      id: "300",
      category: example.possibleCategories[0],
      text:
        "Prima. Bei diesen drei Rechnungen ist das Ergebnis immer um 1 kleiner als das richtige Ergebnis. Dieser Fehlertyp wird Eins-und-eins-Fehler der Nähe um -1 eins bezeichnet und tritt häufig beim Weiterzählen auf.",
    },
  ];

  return example;
}

export default createObject();
